<template>
	<div class="recentltAddedAgentsWrapper">
		<div class="dashboardColumnWrapper">
			<div class="recentlyAddedAgentsWrapper">
				<div class="title-section">
					<div class="title">Recently Added Agents</div>
				</div>
                <div class="card-wrapper">
                    <template v-if="recentltAddedAgents && recentltAddedAgents.length">
                        <div
                        v-for="company in recentltAddedAgents"
                        :key="company._id"
                        class="card-element"
                        >
                            <InformationCard>
                                <template v-slot:topLeft>
                                    <div v-if="company.zone">
                                        {{ company.business_types.join(",") }}
                                    </div>
                                </template>
                                <template v-slot:topRight>
                                    <v-chip
                                        v-if="company.state"
                                        outlined
                                        x-small
                                        color="primary"
                                        >
                                        {{ company.city }}
                                    </v-chip>
                                </template>
                                <template v-slot:mainContent>
                                    {{ company.name }}
                                </template>
                                <template v-slot:mainContentSubtitle>
                                    <div v-if="company.website">
                                        <span v-if="company.website">
                                            {{ company.website }}
                                        </span>
                                    </div>
                                </template>
                            </InformationCard>
                        </div>
                    </template>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
import InformationCard from "../../components/InformationCard";
import { mapActions,mapMutations } from "vuex";

export default{
    name: "RecentyAddedAgents",
    components: { InformationCard },
    data() {
        return {
            showColumnOne: true,
            recentltAddedAgents:[],
        }
    },
    created() {
        this.getRecentlyAddedAgentsList();
    },
    methods: {
        ...mapMutations(["openLoaderDialog", "closeLoaderDialog", "openSnackbar"]),
		...mapActions("Dashboard", ["getRecentlyAddedAgents"]),
        getRecentlyAddedAgentsList() {
				this.openLoaderDialog();
				this.getRecentlyAddedAgents({
				}).then((data) => {
                    this.closeLoaderDialog();
					if (!data.ok) {
                        this.openSnackbar({ text: "Failed to Fetched Recently Added Agents List" });
					}
                    this.recentltAddedAgents = data.list;
				});
			},
    },

}
</script>


<style lang="scss" scoped>

.recentlyAddedAgentsWrapper{
    // flex: 0 0 46%;
	margin: 40px 10px 10px 10px;
	padding: 10px;
	border: 1px solid $primary;
	border-radius: 5px;
	width: 100%;
	position: relative;
	min-height: 60px;
}
.card-wrapper{
    margin-top: 2.5rem;
}

</style>